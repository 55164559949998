<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> 
          Fiche Devis :  {{ projetData.devis_no_interne ? projetData.devis_no_interne : projetData.devis_no_externe }}
        </span>
        <b-badge :variant="statutProjetVariant(projetData.statut)">{{ projetData.statut }}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
            @click="backToListing"
            id="revenir-liste-projets"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <card-information :projet-data="projetData"
        @supprimer-projet="$emit('supprimer-projet', projetData.id)"
        @classer-sans-suite="$emit('classer-sans-suite', projetData.id)"
        @souscrire-projet="$emit('souscrire-projet', projetData)"
        @redirect-externe-projet="$emit('redirect-externe-projet', projetData)"
        @add-contrat-tranform="$emit('add-contrat-tranform', projetData.id, projetData.date_effet, projetData.createur_id, projetData.origine)"
        @dupliquer-devis="$emit('dupliquer-devis', projetData.id, projetData.statut)"
        @finaliser-projet="$emit('finaliser-projet', projetData)"
        @update-projet-externe="$emit('update-projet-externe', projetData, true)"
        @show-prospect="goToAnotherFicheClient" />
      </b-col>
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <tabs-details-fiche-projet 
          :projet-data="projetData"
          :isDocumentAdded="isDocumentAdded"
          @initial-docs-refreshed="initialDocsRefreshed"
          @ajouter-document="$emit('ajouter-document', projetData.etude_id, projetData.id, projetData.devisable_id, projetData.devisable.type, true)"
          @reset-refresh-data-activite="resetRefreshDataActivite"
          :refresh-data-activite="refreshDataActivite" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow,VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardInformation from '@/views/projets/projet/CardInformation.vue'
import tabsDetailsFicheProjet from '@/views/projets/projet/TabsDetailsFicheProjet.vue'

export default {
  components: {
    // UI
    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    //Ui
    CardInformation,
    tabsDetailsFicheProjet
  },
  directives: { Ripple },
  props: {
    projetChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    },
    isProjectUpdated: {
      type: Boolean,
      default: false,
      required: false
    },
    isDocumentAdded: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: false,
      projetData: {} ,
      refreshDataActivite: false
    }
  },
  watch: {
    projetChosen: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.id && val.type) {
          this.showLoading = true
          this.fetchDataProjet(val.id).then(() => {
            this.showLoading = false
          }).catch(()=>{
            this.showLoading = false
          });
        }
      }
    },
    isProjectUpdated: {
      handler(val) {
        if(val) {
          this.showLoading = true
          this.fetchDataProjet(this.projetData.id).then(() => {
            this.showLoading = false
            this.$emit('updated-isprojet-data', false)
          }).catch(()=>{
            this.showLoading = false
            this.$emit('updated-isprojet-data', false)
          });
        }
      }
    }
  },

  methods: {
    resetRefreshDataActivite(isRefresh) {
      this.refreshDataActivite = isRefresh
    },
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
    },
    initialDocsRefreshed(refreshed) {
      this.$emit('initial-docs-refreshed', refreshed)
    },
    async fetchDataProjet(id) {
      await this.$http.get(`/projet/get_list_projet_by_devis_id/${id}`)
          .then(res => {
            this.projetData = res.data
            console.log(this.projetData)
          })
          .catch(err => {
            this.backToListing()
            console.log(err)
          })
    },
    statutProjetVariant(statut) {
      try {
        const statutProjetColor = {
          'Etude': 'light-info',
          'Devis envoye': 'light-info',
          'En attente signature': 'light-info',
          'Devis cloture sans suite': 'light-warning',
          'Refuse par le client': 'light-danger',
          'Dupliquer': 'light-warning',
          'Expire': 'light-dark',
          'Devis valide': 'light-success'
        }
        if (statut) {
          return statutProjetColor[statut]
        }
        return null
      } catch (e) {
        return null
      }
    },
    goToAnotherFicheClient(prospectId, prospectCategorie, prospectType) {
      this.$emit('show-prospect', prospectId, prospectCategorie, prospectType)
    }
  }
}
</script>
