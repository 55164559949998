<template>
  <div>
    <b-card class="mb-2">
      <b-card-text v-if="projetData">
        <b-row>
          <b-col md="12" sm="12">
            <div class="d-flex bd-highlight mb-2">
                <div class="bd-highlight text-uppercase h3 font-weight-bold" v-if="projetData.risque">
                <b>{{ projetData.risque.label }}</b> <b v-if="projetData.assureur">- {{ projetData.assureur.nom_com_assureur }}</b>
                </div>
              <div class="ml-auto bd-highlight" v-if="projetData.etude.etudeable.statut_gda!='ORANGE' && projetData.etude.etudeable.statut_gda!='ROUGE'">
                <div class="demo-inline-spacing">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="projetData.etude && projetData.etude.source === 'Externe' && can('Update project extern') && projetData.statut !== 'Expire'"
                    @click="$emit('update-projet-externe', projetData, true)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Modifier</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="projetData.devis_avancement == 'SOUSCRIPTION' && projetData.statut !== 'Devis cloture sans suite' && projetData.devis_etape_avancement != 'devisenregistre'
                    && projetData.statut !== 'En attente signature' && projetData.statut !== 'Refuse par le client' && projetData.statut !== 'Expire' && projetData.etude.source === 'Interne' && can('Subscribe pricing')"
                    @click="$emit('souscrire-projet', projetData)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Souscrire</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="projetData.devis_avancement == 'SOUSCRIPTION' && projetData.statut !== 'Devis cloture sans suite' && projetData.devis_etape_avancement == 'devisenregistre'
                    && projetData.statut !== 'En attente signature' && projetData.statut !== 'Refuse par le client' && projetData.statut !== 'Expire' && projetData.etude.source === 'Interne' && can('Subscribe pricing')"
                    @click="$emit('redirect-externe-projet', projetData)"
                  >
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Accéder à l'extranet</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="projetData.devis_avancement == 'TARIFICATION' && projetData.statut !== 'Devis cloture sans suite' 
                    && projetData.statut !== 'En attente signature' && projetData.statut !== 'Refuse par le client' && projetData.statut !== 'Expire'	 && projetData.etude.source === 'Interne' && can('Update project intern')"
                    @click="$emit('finaliser-projet', projetData)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Finaliser</span>
                  </b-button>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="can('Update project extern') && projetData.statut !== 'Devis cloture sans suite' && can('Update project intern')"
                    @click="$emit('classer-sans-suite', projetData.id)"
                  >
                    <feather-icon
                      icon="ArchiveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Classer sans suite</span>
                  </b-button>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    v-if="projetData.etude && projetData.etude.source != 'Externe'"
                    @click="$emit('dupliquer-devis', projetData.id, projetData.statut)"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Dupliquer</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    @click="$emit('supprimer-projet', projetData.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Supprimer</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1"
                    @click="AddContratTransformermodel"

                    v-if="projetData.etude && projetData.etude.source == 'Externe'"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Transformer en contrat</span>
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <b-row class="mb-4">
              <b-col cols="12" xl="5">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date d'effet : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        formattingDate($_.get(
                          projetData,
                          "date_effet",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date de création : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        formattingDate($_.get(
                          projetData,
                          "date_creation",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr v-if="projetData.etude && projetData.etude.source == 'Interne'">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date d'envoi du devis : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        changeFormatDateShortNormal($_.get(
                          projetData,
                          "date_envoi",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr v-if="projetData.etude && projetData.etude.source == 'Interne' && projetData.statut == 'En attente signature'">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date limite de signature : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        date_expiration(projetData.date_effet, projetData.date_envoi)
                      }}
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xl="4">
                <table class="mt-2 mt-xl-0 w-100" v-if="projetData.etude">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Prime TTC proposée : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize text-right" v-if="projetData.etude.source === 'Interne'">
                      {{
                          prime_actuelle_preco
                      }}
                    </td>
                    <td class="pb-50 text-capitalize text-right" v-if="projetData.etude.source === 'Externe'">
                      {{
                          this.$_.get(projetData, 'prime', null)
                      }}
                    </td>
                  </tr>
                  <tr >
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Prime TTC actuelle : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize text-right position-relative">
                      {{
                          selected_prime_actuelle
                      }}
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon rounded-circle position-absolute button-prime-actuel"
                        variant="flat-primary" v-if="projetData.etude.source === 'Interne' && prime_actuelle_preco" v-b-modal.modal_prime_actuelle>
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Variation : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize text-right" v-if="projetData.etude.source === 'Interne'">
                      {{ (this.$_.get(projetData.tarification.find(tarification => tarification.preconise == 1), 'prime', 0) - selected_prime_actuelle).toFixed(2) }}
                    </td>
                    <td class="pb-50 text-capitalize text-right" v-if="projetData.etude.source === 'Externe'">
                      {{ (projetData.prime - selected_prime_actuelle).toFixed(2) }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            
            <b-row>
                  <b-col cols="12" xl="12">
                        <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1"><u>Détails</u></h3>
                  </b-col>
                   <b-col cols="12" xl="8" v-if="projetData.risque.label==='2-Roues'">
                     <table class="mt-2 mt-xl-0 w-100" v-if="projetData.etude && projetData.etude.source == 'Interne'">
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Souscripteur : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.id, projetData.devisable.categorie, projetData.devisable.type)">{{(projetData.devisable.civilite ?$_.get(projetData.devisable, "civilite", "").toLowerCase():'') + ' ' + $_.get(projetData.devisable, "nom", "") + " "+ $_.get(projetData.devisable, "prenom", "") }}</a>
                            </td>
                            <!-- <td> <pre>{{projetData.devis_objets[0].objet.objeable.lien}}</pre> </td>                       -->
                          </tr>     
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Conducteur : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.s_is_conducteur == 1"> 
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.id, projetData.devisable.categorie, projetData.devisable.type)">
                                {{( projetData.devisable.civilite ?$_.get(projetData.devisable, "civilite", "").toLowerCase() :'') + ' ' + $_.get(projetData.devisable, "nom", "") + ' '+ $_.get(projetData.devisable, "prenom", "") }}
                                </a>
                            </td>
                            <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.s_is_conducteur == 0 && projetData.devis_objets[0].objet.objeable.conjoint == 1 && projetData.devis_objets[0].objet.objeable.lien === 'CONJOINT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.lien[0].prospeable.id, projetData.devisable.lien[0].prospeable.categorie, projetData.devisable.lien[0].prospeable.type)">{{(projetData.devisable.lien[0].prospeable.civilite ? $_.get(projetData.devisable.lien[0].prospeable, "civilite", "").toLowerCase() :'') + ' ' + $_.get(projetData.devisable.lien[0].prospeable, "nom", "") + " "+ $_.get(projetData.devisable.lien[0].prospeable, "prenom", "") }}</a>
                            </td> 
                            <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.s_is_conducteur == 0 && projetData.devis_objets[0].objet.objeable.conjoint == 0 && projetData.devis_objets[0].objet.objeable.lien === 'ENFANT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisenfants[0].personnephysique.id,projetData.devisenfants[0].personnephysique.categorie,projetData.devisenfants[0].personnephysique.type)">
                                {{ (projetData.devisenfants[0].personnephysique.civilite ?  $_.get(projetData.devisenfants[0].personnephysique, "civilite", "").toLowerCase() : '')  + ' ' + $_.get(projetData.devisenfants[0].personnephysique, "nom", "") + " "+ $_.get(projetData.devisenfants[0].personnephysique, "prenom", "") }}
                              </a>
                            </td> 
                            <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.s_is_conducteur == 0 && projetData.devis_objets[0].objet.objeable.conjoint == 0 && projetData.devis_objets[0].objet.objeable.lien === 'PARENT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisenfants[0].personnephysique.id,projetData.devisenfants[0].personnephysique.categorie,projetData.devisenfants[0].personnephysique.type)">
                                {{ (projetData.devisenfants[0].personnephysique.civilite ?  $_.get(projetData.devisenfants[0].personnephysique, "civilite", "").toLowerCase() : '')  + ' ' + $_.get(projetData.devisenfants[0].personnephysique, "nom", "") + " "+ $_.get(projetData.devisenfants[0].personnephysique, "prenom", "") }}
                              </a>
                            </td> 
                          </tr>     
                          <tr>
                            <th>
                              <span class="font-weight-bolder"
                                >Date de permis : </span
                              >
                            </th>
                            <!-- <td><pre>{{projetData.devis_permis}}</pre></td> -->
                            <td class="pb-50 text-capitalize"   v-for="permi in projetData.devis_permis" :key="permi.id">
                              <span >{{permi.permis.label +' : '+ changeFormatDateShort(permi.permis.date) }}</span>          
                            </td>
                            <!-- <td><pre>{{projetData }}</pre></td> -->
                          </tr>     
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Véhicule : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <span  v-if="projetData.devis_vehicule.vehicule"> {{ projetData.devis_vehicule.vehicule.marque + ' ' + projetData.devis_vehicule.vehicule.modele + ' ' + projetData.devis_vehicule.vehicule.cylindree}}</span>
                            </td>
                            <!-- <td><pre>{{projetData.devis_permis }}</pre></td> -->
                          </tr>     
                        </table>
                        <table class="mt-2 mt-xl-0 w-100" v-else>
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Assuré principal : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.id, projetData.devisable.categorie, projetData.devisable.type)">
                                {{ (projetData.devisable && projetData.devisable.type === 'PERSONNE_PHYSIQUE') ? $_.get(projetData.devisable, "civilite", "") + ' ' + $_.get(projetData.devisable, "nom", "") + " "+ $_.get(projetData.devisable, "prenom", "") : $_.get(projetData.devisable, "denomination_commercial", "") }} 
                              </a>
                            </td>
                          </tr>
                        </table>
                   </b-col>
                  <b-col cols="12" xl="6" v-else>
                    <template v-if="projetData.risque.label !== 'Emprunteur' || (projetData.devis_objets[0].objet.objeable.prets && projetData.devis_objets[0].objet.objeable.prets.length === 0)">
                        <table class="mt-2 mt-xl-0 w-100" v-if="projetData.devis_entreprises && projetData.devis_entreprises[0] && projetData.etude && projetData.etude.source == 'Interne'">
                          <tr v-for="(entreprise) in projetData.devis_entreprises" :key="entreprise.id">
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Entreprise : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                                <a style="text-decoration: underline;" @click="$emit('show-prospect', entreprise.personnemorale.id, entreprise.personnemorale.categorie, entreprise.personnemorale.type)">{{ $_.get(entreprise.personnemorale, "denomination_commercial", "") }}</a>
                            </td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Assuré principal : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.id, projetData.devisable.categorie, projetData.devisable.type)">{{ $_.get(projetData.devisable, "civilite", "") + ' ' + $_.get(projetData.devisable, "nom", "") + " "+ $_.get(projetData.devisable, "prenom", "") }}</a>
                            </td>
                          </tr>
                          <tr v-if="$_.get(projetData.devis_objets[0].objet.objeable, 'conjoint', '') == 1">
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Conjoint : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                                <a style="text-decoration: underline;"
                                @click="$emit('show-prospect', projetData.devisable.lien[0].prospeable.id, projetData.devisable.lien[0].prospeable.categorie, projetData.devisable.lien[0].prospeable.type)">{{
                                  $_.get(projetData.devisable.lien[0].prospeable, "civilite", "") + ' ' + $_.get(projetData.devisable.lien[0].prospeable, "nom", "") + " "+ $_.get(projetData.devisable.lien[0].prospeable, "prenom", "")
                                }}</a>
                            </td>
                          </tr>
                          <tr v-for="(enfant, index) in projetData.devisenfants.slice(0, 2)" :key="enfant.id">
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Enfant {{ index + 1 }} : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;"
                              @click="$emit('show-prospect', enfant.personnephysique.id, enfant.personnephysique.categorie, enfant.personnephysique.type)">
                              {{ $_.get(enfant.personnephysique, "civilite", "") + ' ' + $_.get(enfant.personnephysique, "nom", "") + " "+ $_.get(enfant.personnephysique, "prenom", "") }}
                              </a>
                            </td>
                          </tr>
                        </table>
                        <table class="mt-2 mt-xl-0 w-100" v-else>
                          <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Assuré principal : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devisable.id, projetData.devisable.categorie, projetData.devisable.type)">
                                {{ (projetData.devisable && projetData.devisable.type === 'PERSONNE_PHYSIQUE') ? $_.get(projetData.devisable, "civilite", "") + ' ' + $_.get(projetData.devisable, "nom", "") + " "+ $_.get(projetData.devisable, "prenom", "") : $_.get(projetData.devisable, "denomination_commercial", "") }} 
                              </a>
                            </td>
                          </tr>
                        </table>
                      </template>
                  </b-col>
                  <b-col cols="12" xl="6">
                        <table class="mt-2 mt-xl-0 w-100" v-if="projetData.devisenfants && projetData.devisenfants.length > 2">
                          <tr v-for="(enfant, index) in projetData.devisenfants.slice(2, projetData.devisenfants.length)" :key="enfant.id">
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Enfant {{ index + 3 }} : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize">
                              <a style="text-decoration: underline;"
                              @click="$emit('show-prospect', enfant.personnephysique.id, enfant.personnephysique.categorie, enfant.personnephysique.type)">
                              {{ $_.get(enfant.personnephysique, "civilite", "") + ' ' + $_.get(enfant.personnephysique, "nom", "") + " "+ $_.get(enfant.personnephysique, "prenom", "") }}
                              </a>
                            </td>
                          </tr>
                        </table>
                  </b-col>
            </b-row> 

            <app-collapse id="collapse-besoin" type="margin" v-if="projetData.devis_objets && projetData.devis_objets[0] && projetData.devis_objets[0].objet !== null && projetData.etude && projetData.etude.source == 'Interne' && projetData.risque.label==='Emprunteur' && projetData.devis_objets[0].objet.objeable.prets && projetData.devis_objets[0].objet.objeable.prets[0]">     
                  <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Prêt principal</u></h4>" >
                    <b-col cols="12">
                       <b-row class="mb-4">
                          <b-col cols="12" xl="6">
                                  <table class="mt-2 mt-xl-0 w-100">
                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Type de demande :  </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.type', null) === 'DELIAISON_ASSURANCE'
                                            ? 'Déliaison' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.type', null) === 'SUBSTITUTION'
                                            ? 'Substitution' : ''
                                          }}  
                                        </td>
                                      </tr>
                                     

                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Type de prêt : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].type_pret', null) === 'AMORTISSABLE'
                                              ? 'Amortissable'
                                              : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].type_pret', null) === 'IN_FINE'
                                                ? 'In Fine'
                                                : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].type_pret', null) === 'RELAIS'
                                                  ? 'Relais'
                                                  : ''
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Objet  : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'RESIDENCE_PRINCIPALE'
                                              ? 'Résidence Principale' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'RESIDENCE_SECONDAIRE'
                                              ? 'Résidence Secondaire' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'IVESTISSEMENT_LOCATIF'
                                              ? 'Ivestissement Locatif' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'TRAVAUX'
                                              ? 'Travaux' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'CONSOMMATION'
                                              ? 'Consommation' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'PRET_HYPOTHECAIRE'
                                              ? 'Prêt hypothécaire' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'LEASING'
                                              ? 'Leasing' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].objet', null) === 'AUTRE'
                                              ? 'Autre' 
                                              : ''
                                          }}
                                        </td>
                                      </tr> 
                                      <tr>
                                    <th class="pb-50">
                                      <span class="font-weight-bolder"
                                        >Taux d’intérêts  : </span
                                      >
                                    </th>
                                    <td class="pb-50 text-capitalize">
                                      {{
                                        this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].taux', null)
                                      }}
                                    </td>
                                  </tr> 

                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Type de taux  : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].typeTaux', null) ==='FIXE'
                                            ? 'Fixe' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].typeTaux', null) === 'VARIABLE'
                                            ? 'Variable'
                                            : ''
                                          }}
                                        </td>
                                      </tr> 
                                  </table>
                                  
                                </b-col>
                                <b-col cols="12" xl="6">
                                  <table class="mt-2 mt-xl-0 w-100">
                                    <tr>

                                      <th class="pb-50">
                                        <span class="font-weight-bolder">Date d’effet : </span>
                                      </th>
                                      <td class="pb-50 text-capitalize">
                                        {{
                                          formattingDate(this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].dateEffet', null))
                                        }}
                                      </td>

                                  </tr>
                                  <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder">Nature </span>
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].nature', null) === 'PRET_IMMOBILIER'
                                            ? 'Prêt Immobilier' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].nature', null) === 'PRET_PERSONNEL'
                                            ? 'Prêt Personnel' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].nature', null) === 'PRET_PROFESSIONNEL'
                                            ?  'Prêt Professionnel'
                                            : ''
                                           }}
                                        </td>
                                  </tr>
                                  <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Date de signature  : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            formattingDate(this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].dateSignature', null))
                                          }}
                                        </td>
                                      </tr> 
                                                         
                                  <tr>
                                    <th class="pb-50">
                                      <span class="font-weight-bolder"
                                        >Montant en euros (durée en mois)  : </span
                                      >
                                    </th>
                                    <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.prets[0].has_pret_palier">
                                      {{ 
                                        projetData.devis_objets[0].objet.objeable.prets[0].paliers.reduce((acc, palier) => acc + palier.montant, 0) 
                                      }}
                                      
                                      ({{ 
                                        projetData.devis_objets[0].objet.objeable.prets[0].paliers.reduce((acc, palier) => acc + palier.duree, 0) 
                                      }})
                                    </td>
                                    <td v-else>
                                      {{
                                                  this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].capital', null)
                                      }}
                                      ({{
                                                  this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[0].dureeHorsDif', null)
                                      }})
                                    </td>
                                  </tr>

                                  </table>

                                </b-col>
                                
                            </b-row>
                        </b-col>
                                
                  </app-collapse-item>
                </app-collapse>


                <app-collapse id="collapse-besoin" type="margin" v-if="projetData.devis_objets && projetData.devis_objets[0] && projetData.devis_objets[0].objet !== null && projetData.etude && projetData.etude.source == 'Interne' && projetData.risque.label==='Emprunteur' && projetData.devis_objets[0].objet.objeable.prets && projetData.devis_objets[0].objet.objeable.prets[0] &&  projetData.devis_objets[0].objet.objeable.has_pret_secondaire">     
                  <app-collapse-item ref="tab-collapse-item-1" :is-visible="false" title="<h4 class='title-custom-wizard'><u>Prêt secondaire</u></h4>">
                    <b-row>
                      <b-col cols="12" xl="12" >
                        <table class="mt-2 mt-xl-0 w-100">
                        <b-row>
                                <b-col cols="12" xl="6">
                                  <table class="mt-2 mt-xl-0 w-100">

                                      <tr >
                                        <th class="pb-50">
                                          <span class="font-weight-bolder">Date d’effet : </span>
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            formattingDate(this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].dateEffet', null))
                                          }}
                                        </td>
                                      </tr>
 
                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Type de prêt : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].type_pret', null) === 'AMORTISSABLE'
                                            ? 'Amortissable' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].type_pret', null) ==='IN_FINE'
                                            ? 'In Fine' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].type_pret', null) === 'RELAIS'
                                            ? 'Relais' 
                                            : ''
                                          }}
                                        </td>


                                      </tr>
                                                                           

                                      <tr>
                                            <th class="pb-50">
                                                <span class="font-weight-bolder"
                                                  >Taux d’intérêts  : </span
                                                >
                                              </th>
                                              <td class="pb-50 text-capitalize">
                                                {{
                                                  this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].taux', null)
                                                }}
                                              </td>
                                          </tr>

                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >Type de taux  : </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          {{
                                            this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].typeTaux', null) === 'FIXE'
                                            ? 'Fixe' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].typeTaux', null) === 'VARIABLE'
                                            ? 'Variable'
                                            : ''
                                          }}
                                        </td>

                                      </tr>


                                  </table>
                                </b-col>
                                <b-col cols="12" xl="6">
                                    <table class="mt-1 mt-xl-0 w-100">

                                        <tr>
                                              <th class="pb-50">
                                                <span class="font-weight-bolder"
                                                  >Objet  : </span
                                                >
                                              </th>
                                              <td class="pb-50 text-capitalize">
                                                {{
                                                  this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null)==='RESIDENCE_PRINCIPALE'
                                                  ? 'Résidence Principale' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) ==='RESIDENCE_SECONDAIRE'
                                                  ? 'Résidence Secondaire' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'IVESTISSEMENT_LOCATIF'
                                                  ? 'Ivestissement Locatif' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'TRAVAUX'                             
                                                  ? 'Travaux' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'CONSOMMATION'
                                                  ? 'Consommation' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'PRET_HYPOTHECAIRE'
                                                  ? 'Prêt hypothécaire' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'LEASING'
                                                  ? 'Leasing' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].objet', null) === 'AUTRE'
                                                  ? 'Autre'
                                                  : ''
                                                }}
                                              </td>
                                        </tr>
                                          
                                          <tr>
                                            <th class="pb-50">
                                              <span class="font-weight-bolder">Nature </span>
                                            </th>
                                            <td class="pb-50 text-capitalize">
                                              {{
                                                this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].nature', null) === 'PRET_IMMOBILIER'
                                                ? 'Prêt Immobilier' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].nature', null) === 'PRET_PERSONNEL'
                                                ? 'Prêt Personnel' : this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].nature', null) === 'PRET_PROFESSIONNEL'
                                                ?  'Prêt Professionnel'
                                                : ''
                                              }}
                                            </td>
                                          </tr> 
                                          <tr>
                                              <th class="pb-50">
                                                <span class="font-weight-bolder"
                                                  >Date de signature  : </span
                                                >
                                              </th>
                                              <td class="pb-50 text-capitalize">
                                                {{
                                                  formattingDate(this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].dateSignature', null))
                                                }}
                                              </td>

                                          </tr>                                          
                                          <tr>
                                            <th class="pb-50">
                                              <span class="font-weight-bolder"
                                                >Montant en euros (durée en mois)  : </span
                                              >
                                            </th>
                                            <td class="pb-50 text-capitalize" v-if="projetData.devis_objets[0].objet.objeable.prets[1].has_pret_palier">
                                              {{ 
                                                projetData.devis_objets[0].objet.objeable.prets[1].paliers.reduce((acc, palier) => acc + palier.montant, 0) 
                                              }}
                                              
                                              ({{ 
                                                projetData.devis_objets[0].objet.objeable.prets[1].paliers.reduce((acc, palier) => acc + palier.duree, 0) 
                                              }})
                                            </td>
                                            <td v-else>
                                              {{
                                                          this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].capital', null)
                                              }}
                                              ({{
                                                          this.$_.get(projetData, 'devis_objets[0].objet.objeable.prets[1].dureeHorsDif', null)
                                              }})
                                            </td>
                                          </tr>

                                    </table>
                                </b-col>
                            </b-row>
                        </table>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>

              
                <app-collapse id="collapse-besoin" type="margin" v-if="projetData.devis_objets && projetData.devis_objets[0] && projetData.devis_objets[0].objet !== null && projetData.etude && projetData.etude.source == 'Interne' && projetData.risque.label==='Emprunteur' && projetData.devis_objets[0].objet.objeable.prets && projetData.devis_objets[0].objet.objeable.prets[0]">     
                  <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Assuré(s)</u></h4>" >
                    <b-row>
                      <b-col cols="12" xl="6" >
                        <table class="mt-2 mt-xl-0 w-100">
                        <b-row>
                                <b-col cols="12" xl="12" v-if="projetData.risque.label==='Emprunteur'">
                                  <table class="mt-2 mt-xl-0 w-100">
 
                                      <tr>
                                        <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >{{ this.$_.get(projetData, 'devis_objets[0].objet.objeable.typeAssure', null) == 'EMPRUNTEUR' ? 'Emprunteur' : 'Caution' }}</span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable.id, projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable.categorie, projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable.type)">
                                           {{  $_.get(projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable, "civilite", "") + ' ' + $_.get(projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable, "nom", "") + " "+ $_.get(projetData.devis_objets[0].objet.objeable.prets[0].emprunteurable, "prenom", "")  }} 
                                          </a>
                                        </td>

                                      </tr>

                                  </table>
                                </b-col>
                            </b-row>
                        </table>
          
                      </b-col>
                    <b-col cols="12" xl="6" v-if="projetData.devis_objets[0].objet.objeable.has_co_emprunteur">
                        <table class="mt-2 mt-xl-0 w-100">
                                    <tr>
                                      <th class="pb-50">
                                          <span class="font-weight-bolder"
                                            >{{ this.$_.get(projetData, 'devis_objets[0].objet.objeable.coempTypeAssure', null) == 'CO_EMPRUNTEUR' ? 'Co-emprunteur' : 'Caution' }} </span
                                          >
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                          <a style="text-decoration: underline;" @click="$emit('show-prospect', projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable.id, projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable.categorie, projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable.type)">
                                           {{  $_.get(projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable, "civilite", "") + ' ' + $_.get(projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable, "nom", "") + " "+ $_.get(projetData.devis_objets[0].objet.objeable.prets[0].co_emprunteurable, "prenom", "")  }} 
                                          </a>
                                          
                                        </td>
                                    </tr>
                          </table>
                    </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>           



        <b-row>
            <b-col cols="12" xl="12">
                <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1 mt-2"><u>Origine</u></h3>
          </b-col>
            <b-col cols="12" xl="8">
              <table class="mt-2 mt-xl-0 w-100">

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Crée par : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                          this.$_.get(projetData, 'createur_devis.user.personne_physique.prenom', null)
                      }}
                      {{
                          this.$_.get(projetData, 'createur_devis.user.personne_physique.nom', null)
                      }} 
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Intermédiaire(s) : </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      
                      <template v-for="intermediaire in projetData.courtiers_users_by_intermediares">
                        {{ intermediaire.user.personne_physique.prenom.replace('_', ' ') }} {{ intermediaire.user.personne_physique.nom.replace('_', ' ') }} <br>
                      </template>
                    </td>
                </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Type : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                          this.$_.get(projetData, 'origine', null)
                      }}
                    </td>
                  </tr>

              </table>
            </b-col>
         
        </b-row>



          </b-col>
        </b-row>
      </b-card-text>
           <!-- basic modal -->
      <b-modal id="modal_prime_actuelle" hide-footer centered title="Modifier Prime TTC actuelle" @hidden="resetModal" v-if="projetData.etude.etudeable.statut_gda!='ORANGE' && projetData.etude.etudeable.statut_gda!='ROUGE'">
        <b-overlay
        :show="showModalLoading"
        no-wrap
        />
        <validation-observer ref="formUpdatePrimeActuelle">
          <b-form class="p-2" @submit.prevent="updatePrimeActuel">
            <b-form-group label="Prime TTC actuelle" label-for="prime-actuelle">
              <validation-provider #default="{ errors }" name="prime TTC actuelle">
                <b-form-input type="number" min="0" id="prime_actuel" v-model="selected_prime_actuelle" trim placeholder="Prime TTC actuelle" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" :disabled="!selected_prime_actuelle">
                Modifier
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- basic modal -->
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BRow, VBToggle, BModal,  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BOverlay } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BModal,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    flatPickr,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    projetData: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    }
  },
  watch: {
    projetData : {
      handler(val) {
        if(val) {
          this.initialSelectedPrimeActuel(val)
          this.getPrimePreconise(val)
        }
        
      }
    }
  },
  data() {
    return {
      selected_prime_actuelle: null,
      prime_actuelle_keep: null,
      prime_actuelle_preco: null,
      showModalLoading: false,
      isSubmited: false,
      
    }
    
  },
  methods: {
    AddContratTransformermodel(){
      this.$emit('add-contrat-tranform',this.projetData.id, this.projetData.date_effet, this.projetData.createur_id, this.projetData.origine)

    },
    resetModal() {
      if(!this.isSubmited) {
        this.selected_prime_actuelle = this.prime_actuelle_keep
      } else this.isSubmited = false
        
    },
    initialSelectedPrimeActuel(devis) {
        if(devis) {
          this.selected_prime_actuelle = this.prime_actuelle_keep = (devis.etude.source == 'Interne') ? this.$_.get(devis.tarification.find(tarification => tarification.preconise == 1), 'prime_actuel', null) : this.$_.get(devis, "prime_actuel", "");
        }
    },
    getPrimePreconise(devis) {
      if(devis.etude && devis.etude.source == 'Interne')
        this.prime_actuelle_preco = this.$_.get(devis.tarification.find(tarification => tarification.preconise == 1), 'prime', null)
    },
    updatePrimeActuel() {
      this.$refs.formUpdatePrimeActuelle.validate().then(success => {
        if (success) {
          this.showModalLoading = true
          this.$http.post(`projet/updatePrimeActuel`, {devisId:this.projetData.id, primeActuel: this.selected_prime_actuelle})
          .then(response => {
            if (response.data.success) {
              this.selected_prime_actuelle = this.prime_actuelle_keep = response.data.data.prime_actuel
              this.messageToast(response.data.message, 'Succès', 'success')
            } else {
              this.initialSelectedPrimeActuel(this.projetData)
              this.messageToast(response.data.message, 'Erreur', 'danger')
            }
            this.showModalLoading = false
            this.isSubmited = true
            this.$bvModal.hide('modal_prime_actuelle')
          })
          .catch(err => {
            this.initialSelectedPrimeActuel(this.projetData)
            this.messageToast(err.message, 'Erreur', 'danger')
            this.showModalLoading = false
          })
        }
      });
    },
    date_expiration(data_effet, date_envoi) {
      if(data_effet && date_envoi) {
        let date_envoi = moment(moment(date_envoi).format('YYYY-MM-DD')).add(14, 'days')
        let date_expiration = moment(moment(data_effet).add(1, 'M')).startOf('month').subtract(1, "days")
        let dt = date_envoi > date_expiration ? date_expiration : date_envoi
        return this.changeFormatDateShortNormal(dt)
      }
      return ''
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("-");
        date_formatting =
          date_formatting[2] +
          "/" +
          date_formatting[1] +
          "/" +
          date_formatting[0];
        return date_formatting;
      }
      return null;
    }
  },
}
</script>
<style> 
  .button-prime-actuel {
    right: -37px;
    top: 0;
    height: 24px;
    width: 24px;
    padding: 3px!important;
  }
  
</style>